import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20b8662a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal-container",
  ref: "modalContentContainer"
}
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "text-h3" }
const _hoisted_5 = {
  key: 0,
  class: "icon-close"
}
const _hoisted_6 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "css-class": "dview-modal",
    "is-open": _ctx.isModalOpen,
    "swipe-to-close": "true",
    onDidDismiss: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { scrollY: "false" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_icon, {
                class: "icon-message-type",
                icon: _ctx.warningOutline
              }, null, 8, ["icon"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
              ]),
              (_ctx.showCloseButton)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.closeOutline,
                      onClick: _ctx.closeModal
                    }, null, 8, ["icon", "onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createTextVNode("Content")
              ], true)
            ]),
            (_ctx.showCtaButton)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  onClick: _ctx.ctaButtonAction,
                  class: "primary-outline"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.ctaButtonText), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ], 512)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["is-open", "onDidDismiss"]))
}